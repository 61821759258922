<div class="background">
	<div width="100%">
		<img class="title" src="/assets/img/Strapline2_black.png">
	</div>
	<div class="maintext">
		We are busy, behind the door, working on a world of opportunity for our neurodiverse community. We have big plans and will share more as we progress.
		In the meantime, if you want to keep a tab on us, follow our social pages on the links below. When we launch, you'll be amongst the first to know...
		<div class="maintext2">
			Copyright © 2020, Diversitus cic. All rights reserved
		</div>
	</div>
	<div class="socialcontainer">
		<!-- <a href="https://twitter.com/diversitus"><img class="sociallogo" src="Users/matthewparker/Documents/html/images/twitter.png"></a> -->
		<a href="https://www.facebook.com/diversitus" target="_blank"><img class="sociallogo" src="/assets/img/fb.png"></a>
		<a href="https://www.linkedin.com/company/diversitus" target="_blank"><img class="sociallogo" src="/assets/img/linkedin.png"></a>
		
	</div>
  </div>
<router-outlet></router-outlet>
